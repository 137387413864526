import * as React from "react";
import Seo from "../components/seo";
import { useEffect, useState } from "react";
import { isLoggedIn } from "../utils/auth";
import StandardLayout from "../components/Layout/StandardLayout";
import OnboardingLayout from "../components/Layout/OnboardingLayout";

const NotFoundPage = () => {
  const [loggedIn, setLoggedIn] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    async function checkLoginStatus() {
      setLoggedIn(await isLoggedIn());
    }

    void checkLoginStatus();
  }, []);

  return loggedIn ? (
    <StandardLayout>
      <Seo title="404: Not found" />
      <h1>404: Not Found</h1>
      <p>Sorry... we were not able to retrieve the requested resource.</p>
    </StandardLayout>
  ) : (
    <OnboardingLayout>
      <Seo title="404: Not found" />
      <h1>404: Not Found</h1>
      <p>Sorry... we were not able to retrieve the requested resource.</p>
    </OnboardingLayout>
  );
};

export default NotFoundPage;
